<template>
    <div id="supplier_address" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/5 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress v-if="!isFromLoan" :percent="50" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full pb-10 pt-10" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMounted" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <p v-if="!isFromLoan" class="mb-2">PASO 3 DE 4</p>
                                                <h1 class="text-2xl card-sub-title">
                                                    {{ isMoral ? "Detalla la dirección de donde opera tu negocio" : "Detalla tu dirección personal" }}
                                                </h1>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div v-if="isMounted && allSections.length > 0" class="main-form mt-base">
                                            <div class="vx-row">
                                                <div v-for="section in allSections" :key="section.id" :class="colClass">
                                                    <!-- <div class="mb-4">
                                                        <h4 :class="section.public_description != null ? 'mb-2' : ''">{{
                                                            section.public_name }}</h4>
                                                        <p v-if="section.public_description != null">{{
                                                            section.public_description }}</p>
                                                    </div> -->
                                                    <div class="vx-row">
                                                        <template v-for="f in section.fields">
                                                            <!-- {{ f.db_field }} -->
                                                            <!-- Campos automaticos -->
                                                            <form-field :ref="`section_id_${section.id}`"
                                                                v-if="isAutoFormField(f)" 
                                                                :key="f.id" 
                                                                :f="f"
                                                                :dataContainer="getContainerForField(section, f)"
                                                                :collectionsRoot="collections"
                                                                :onChangeReceptor="onFieldChange"
                                                                :hasBackofficeAccess="false"
                                                                :blockedByDocument="f.blockedByDocument"
                                                                :evaluator="evaluateCondition" :inputClassLarge="true"
                                                                :countriesList="collections.countries"
                                                                :editableByBackoffice="true" />
                                                        </template>

                                                    </div>
                                                </div>
                                            </div>
                                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                class="mb-5" color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <vs-alert v-if="successMssg" icon-pack="feather" icon="icon-check" class="mb-5"
                                                color="success">
                                                <span class="font-regular">{{ successMssg }}</span>
                                            </vs-alert>
                                            <div class="vx-row mt-10">
                                                <div class="vx-col w-full">
                                                    <!-- BOTONES -->
                                                    <div class="flex justify-center">
                                                        <vs-button class="mt-2 vs-button-dark"
                                                            @click="saveSections(`section_id_${current_section}`)">{{ isFromLoan ? 'Guardar' : 'Continuar' }}</vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN-DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import FormField from '@components/forms/FormField.vue';
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';

const sectionsToContainers = [
    { id: 16, containers: [
            { db: 'addresses', path: 'user.business.address' },
            { db:'phones', path:'user.business.phone', arrayDefs:{ onNull: 'newPhone'} }
        ] 
    },
    {
        id: 32, containers: [{ db: 'addresses', path: 'user.business.personal.address' }]
    }
];

const sectionsToPersonalContainers = [
    { id: 16, containers: [
            { db: 'addresses', path: 'user.business.address' },
            { db:'phones', path:'user.business.phone', arrayDefs:{ onNull: 'newPhone'} }
        ] 
    },
    {
        id: 32, containers: [{ db: 'addresses', path: 'user.personal.address' }]
    }
];

const sectionsToContainersForStakeholders = [
    { id: 16, containers: [
            { db: 'addresses', path: 'business_profile.address' },
            { db:'phones', path:'user.business.phone', arrayDefs:{ onNull: 'newPhone'} }
        ] 
    },
    {
        id: 32, containers: [{ db: 'addresses', path: 'personal_profile.address' }]
    }
];

export default {
    name: "ApplicantOnboardingAddress",
    mixins: [inputHelper, formatHelper, docsHelper, formHelper],
    components: {
        FormField
    },
    props: ["onboardingStepData", "isMoral", "isFromLoan", "isStakeholder", "isFromPersonal", "isRepLegal"],
    data() {
        return {
            requiredRules: "required",
            errorMssg: null,
            successMssg: null,
            isMounted: false,
            loading: false,
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: "(Requerido)",
            isMainOnboardingStepVisible: true,
            formId: 2,
            pmSections: [16],
            pfSections: [32],
            form: {},
            allSections: [],
            formSections: [],

            documentsFields: [],
            documentsStates: [],

            validatedDocuments: [],
            allDocumentFields: [],
            // objeto principal
            base: {},
            // auxiliares para evaluacion de condiciones
            mexicoCountryId: 700,
            // objetos para actualizaciones
            basePayload: {},
            validatedFields: [],
            totalFields: 0,
            totalValidFields: 0,
            totalInvalidFields: 0,
            skipMissingWarnings: false,
            collections: {
                cities: [],
                states: [],
                countries: [],
                foreign_countries: [],
                family_groups: [],
                tools: [],
                customer_types: [],
                banks: [],
                fiscal_states: [],
                fiscal_cities: [],
                fiscal_neighborhoods: [],
                neighborhoods: [],

                states_application_business_address: [],
                cities_application_business_address: [],
                neighborhoods_application_business_address: [],

                states_application_address: [],
                cities_application_address: [],
                neighborhoods_application_address: [],
            },
        };
    },
    async mounted() {
        this.showLoading(true);
        this.isMounted = false;
        this.base = this.onboardingStepData;
        await this.getCollections();
        await this.getSections();
        this.isMounted = true;
        this.showLoading(false);
    },
    computed: {
        current_section() {
            if(this.isRepLegal) {
                return this.pfSections[0];
            }
            return this.isMoral ? this.pmSections[0] : this.pfSections[0];
        },
        personal() {
            if(this.isStakeholder) {
                return this.base.personal_profile;
            }

            if(this.isRepLegal) {
                return this.business.personal;
            }

            return this.base.user.personal;
        },
        business() {
            if(this.isStakeholder) {
                return this.base.business_profile;
            }
            return this.base.user.business;
        },
        address() {
            return this.isMoral ? this.business.address : this.personal.address;
        },
    },
    watch: {
        loading: function (newVal, oldVal) {
            if (newVal == true) {
                this.$vs.loading();
            }
            else {
                this.$vs.loading.close();
            }
        }
    },
    methods: {
        newPhone(){
            return {country_calling_code: null, phone: null};
        },
        async getCollections() {
            try {
                let collectionsObjects = ['countriesList', 'foreignCountriesList', 'statesList', 'familyGroups', 'availableSupplierTools',
                    'supplierCustomerTypesCatalog', 'banksForMexico'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                let colls = res.data;
                this.collections.countries = colls.countriesList;
                this.collections.foreign_countries = colls.foreignCountriesList;
                this.collections.states = colls.statesList;
                this.collections.family_groups = colls.familyGroups;
                this.collections.tools = colls.availableSupplierTools;
                this.collections.customer_types = colls.supplierCustomerTypesCatalog;
                this.collections.banks = colls.banksForMexico;

            } catch (e) {
                this.warn(e);
                this.failed = true;
            }
        },
        async getSections() {
            const sections = this.isMoral ? this.pmSections : this.pfSections;
            const res = await axios.post("/api/v1/forms/getSectionsByArrayIds", { section_ids: sections });
            this.allSections = res.data.sections;

            await this.asyncForEach(this.allSections, async (s) => {
                s.containers = this.getContainersForSection(s.id);
                s.documentFields = [];

                await this.asyncForEach(s.fields, async (f) => {
                    this.formFieldSetter(f, this);
                });
            });
        },
        getContainersForSection(section_id) {
            let c = null;
            if(this.isFromPersonal) {
                c = sectionsToPersonalContainers.find(f => f.id == section_id);
            } else {
                if(this.isStakeholder) {
                    c = sectionsToContainersForStakeholders.find(f => f.id == section_id);
                } else {
                    c = sectionsToContainers.find(f => f.id == section_id);
                }
            }
            if (!c) {
                this.warn("Missing containers definition for section " + section_id);
                return null;
            }
            else {
                let sectionContainers = [];
                c.containers.forEach(cc => {
                    let con = this.setContainerFromDef(cc);
                    sectionContainers.push({ db: cc.db, path: cc.path, container: con });
                })
                return sectionContainers;
            }
        },
        getContainerForField(section, f) {
            let c = section.containers.find(sc => sc.db == f.db_table);
            if (!c) {
                this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
                return null;
            }
            else {
                return c.container;
            }
        },
        setContainerFromDef(c) {
            let container = this.nestedFieldValue(this.base, c.path);
            if (Array.isArray(container)) {
                let ac = null;
                if (c.arrayDefs) {
                    if (c.arrayDefs.getFirst == true) {
                        ac = container[0];
                    }
                    else if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval](container);
                    }
                }
                if (!ac) {
                    if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                    else { ac = {} }
                    container.push(ac);
                }
                return ac;
            }
            else {
                if (c.path == 'bank_accounts_bank') {
                    let accs = this.nestedFieldValue(this.base, 'user.business.bank_accounts');
                    let nbankAcc = accs[0];
                    return nbankAcc.bank;
                }
                if (container == null && c.arrayDefs) {
                    let ac = null;
                    if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval]();
                        return ac;
                    }
                    if (!ac) {
                        if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                        else { ac = {} }
                        container = ac;
                    }
                }
                return container;
            }
        },
        /* evaluator */
        evaluateCondition(condition) {
            return this.evaluateConditionBase(this, condition);
        },
        /* on change receptor */
        onFieldChange(method) {
            if (method != null && method in this) {
                this[method]();
            }
        },
        newPhone() {
            return { country_calling_code: null, phone: null };
        },

        // GUARDAR
        async saveSections(gslug) {
            // this.loading = true;
            this.skipMissingWarnings = false;
            let result = await this.runValidations(gslug);
            if (!result) {
                if (!this.skipMissingWarnings) {
                    this.missingFieldsNotif();
                }
            } else {
                //guardar solo la informacion que se ha modificado
                this.basePayload = this.collectFormData(gslug);
                if (this.objectIsEmpty(this.basePayload)) {
                    this.saveSuccessNotif();
                    return;
                }
                
                this.showLoading(true, "Guardando información...");
                this.basePayload.id = this.base.id;
                try {
                    // ejecutar guardado
                    this.injectAccountMetadataToPayload(this.basePayload);
                    await axios.put(`/api/v1/applicant/${this.ApplicantId}/deepUpdate`, this.basePayload);
                    if(!this.isFromLoan) {
                        await axios.put(`/api/applicant-onboarding/put/update-business-address`, {
                            applicant_id: this.ApplicantId
                        });
                    }
                    // solicitar la informacion actualizada del modelo
                    this.showLoading(false);
                    await this.$emit("updated", this.address);
                    this.saveSuccessNotif();
                }
                catch (error) {
                    this.showLoading(false);
                    this.warn(error);
                    this.failedOperationNotif(null, null);
                }
                this.showLoading(false);
            }
            this.loading = false;
        },
        async runValidations(gslug) {
            // validar componentes tipo FormField
            let res1 = await this.validateFormFields(gslug);
            if (!res1) {
                return false;
            }

            // validar inputs instanciados en la vista
            let res2 = await this.$validator.validateAll();
            if (!res2) {
                return false;
            }

            // retornar true si todas las validaciones fueron positivas
            return true;
        },
        resetCounters() {
            this.totalFields = 0;
            this.totalValidFields = 0;
            this.totalInvalidFields = 0;
        },
        collectFormData(gslug) {
            let payload = {};
            const sectionId = gslug.substr(11);

            let section = this.allSections.find(s => s.id == sectionId);
            section.containers.forEach(cc => {
                let fields = this.validatedFields.filter(vf => vf.db == cc.db);
                if (fields.length < 1) {
                    return;
                }

                if (cc.path == "base") {
                    fields.forEach(f => {
                        payload[f.fname] = cc.container[f.fname];
                    });
                }
                else {
                    let obj = null;

                    obj = { id: cc.container.id };
                    fields.forEach(f => {
                        obj[f.fname] = cc.container[f.fname];
                    });

                    // ajuste para objetos tipo array
                    this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
                }
            });
            return payload;
        },
        async validateFormFields(refGroup) {
            let allValid = true;
            this.validatedFields = [];
            let grefs = this.$refs[refGroup];
            let f;
            for (let p in grefs) {
                f = grefs[p];
                let r = await f.checkForValidDirty();
                if (r.valid == false) {
                    allValid = false;
                }
                else if (r.dirty == true) {
                    let sp = f.specialValidation();
                    if (sp != null && sp.method in this) {
                        let res = await this[sp.method]();
                        let n = f.rawFieldName;
                        if (!res) {
                            if (f.db_name === "rfc" && res.length === 0) {
                                continue;
                            }
                            this.skipMissingWarnings = true;
                            allValid = false;
                            f.setError(sp.error);
                            this.errorNotif(n, sp.error, 10000);
                            continue;
                        }
                    }
                    this.validatedFields.push(r);
                }
            }
            return allValid;
        },
        verify_zip_code_application_business_address() {
            this.verifyZipCode(this.business.address, {
                zipCodeVariable: 'zip_code_application_business_address', 
                statesArray: 'states_application_business_address', 
                citiesArray: 'cities_application_business_address', 
                neighborhoodsArray: 'neighborhoods_application_business_address'
            });
        },
        set_zip_code_values_application_business_address() {
            this.setZipCodeAddressValues(this.business.address_id, this.business.address, {
                zipCodeVariable: 'zip_code_application_business_address',
                statesArray: 'states_application_business_address',
                citiesArray: 'cities_application_business_address',
                neighborhoodsArray: 'neighborhoods_application_business_address'
            });
        },
        verify_zip_code_application_address() {
            this.verifyZipCode(this.personal.address, {
                zipCodeVariable: 'zip_code_application_address', 
                statesArray: 'states_application_address', 
                citiesArray: 'cities_application_address', 
                neighborhoodsArray: 'neighborhoods_application_address'
            });
        },
        set_zip_code_values_application_address() {
            this.setZipCodeAddressValues(this.personal.address_id, this.personal.address, {
                zipCodeVariable: 'zip_code_application_address',
                statesArray: 'states_application_address',
                citiesArray: 'cities_application_address',
                neighborhoodsArray: 'neighborhoods_application_address'
            });
        },
    },
};
</script>

<style>
.documents {
    border: 2px solid #c2c2c2;
    border-radius: 5px;
    padding: 2rem;
}

.documents ul {
    margin-left: 1rem;
}

</style>
