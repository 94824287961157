export class ScoreService {
    static path = 'score';

    /**
     * @return {String}
     */
    static getEndpoint() {
        return process.env.VUE_APP_RISK_MODEL_ENDPOINT;
    }

    static async get(rfc) {
        return await axios.get(`https://apigw.redgirasol.com/api/score/cdc-service/check/${rfc}`);
    }
    
    static async requestPF(data) {
        return await axios.post(`/api/credit-score/personal`, data)
    }

    static async publicRequestPF(data) {
        return await this.publicApiPost(`/api/credit-score/personal`, data)
    }

    static async requestPM(data) {
        return await axios.post(`/api/credit-score/moral`, data)
    }

    static async update(rfc, data) {
        return await axios.post(`https://apigw.redgirasol.com/api/score/cdc-service/score/${rfc}`, data)
    }
}
